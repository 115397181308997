import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }
        return Promise.reject(err)
    })
}

Vue.use(VueRouter)

const routes = [
    {path: '/',                     name: 'index',           component: () => import('@/views/index')},
    {path: '/login',                name: 'login',           component: () => import('@/views/login'),          meta: { noAuth: true }},
    {path: '/login/:sns',           name: 'login/:sns',      component: () => import('@/views/login'),          meta: { noAuth: true }},
    {path: '/sleep',                name: 'sleep',           component: () => import('@/views/sleep')},
    {path: '/ie',                   name: 'ie',              component: () => import('@/views/ie'),             meta: { layout: 'NoLayout' }},
    {path: '/invalidToken',         name: 'invalidToken',    component: () => import('@/views/invalidToken'),   meta: { layout: 'NoLayout' }},
    {path: '/notification',         name: 'notification',    component: () => import('@/views/notification'),   meta: { auth: true }},

    {path: '/join',                 name: 'join',            component: () => import('@/views/join'),           meta: { noAuth: true }},
    {path: '/join/email',           name: 'join/email',      component: () => import('@/views/join/email'),     meta: { noAuth: true }},
    // {path: '/join/sns',             name: 'join/sns',        component: () => import('@/views/join/sns'),       meta: { noAuth: true }},
    {path: '/join/exist',           name: 'join/exist',      component: () => import('@/views/join/exist'),     meta: { noAuth: true}},
    {path: '/join/complete',        name: 'join/complete',   component: () => import('@/views/join/complete')},
    {path: '/find',                 name: 'find',            component: () => import('@/views/find'),           meta: { noAuth: true }},
    {path: '/newPassword',          name: 'newPassword',     component: () => import('@/views/newPassword'),    meta: { layout: 'NoLayout' }},

    {path: '/policy/1',             name: 'policy/1',        component: () => import('@/views/policy/1')},
    {path: '/policy/2',             name: 'policy/2',        component: () => import('@/views/policy/2')},
    {path: '/policy/3',             name: 'policy/3',        component: () => import('@/views/policy/3')},
    {path: '/policy/4',             name: 'policy/4',        component: () => import('@/views/policy/4')},

    {path: '/search',               name: 'search',          component: () => import('@/views/search')},
    {path: '/search/:type',         name: 'search/more',     component: () => import('@/views/search/more')},

    {path: '/tip',                  name: 'tip',             component: () => import('@/views/tip/list')},
    {path: '/tip/new',              name: 'tip/new',         component: () => import('@/views/tip/save'),            meta: { auth: true, layout: 'NoLayout' }},
    {path: '/tip/:idx',             name: 'tip/detail',        component: () => import('@/views/tip/detail')},
    {path: '/tip/:idx/modify',      name: 'tip/modify',      component: () => import('@/views/tip/save'),            meta: { auth: true, layout: 'NoLayout' }},
    {path: '/lab',                  name: 'lab',             component: () => import('@/views/lab/list')},
    {path: '/lab/:idx',             name: 'lab/detail',        component: () => import('@/views/lab/detail')},
    {path: '/md',                   name: 'md',              component: () => import('@/views/md/list')},
    {path: '/md/:id',               name: 'md/:id',          component: () => import('@/views/md/listById')},
    {path: '/md/:id/:idx',          name: 'md/:id/:idx',     component: () => import('@/views/md/detail')},
    
    {path: '/ggomunity',            name: 'ggomunity',       component: () => import('@/views/ggomunity/list')},
    {path: '/ggomunity/new',        name: 'ggomunity/new',   component: () => import('@/views/ggomunity/save'),      meta: { auth: true }},
    {path: '/ggomunity/:idx',       name: 'ggomunity/detail',  component: () => import('@/views/ggomunity/detail')},
    {path: '/ggomunity/:idx/modify',name: 'ggomunity/modify',component: () => import('@/views/ggomunity/save')},

    {path: '/event',                name: 'event',           component: () => import('@/views/event/list')},
    {path: '/event/:idx',           name: 'event/:idx',      component: () => import('@/views/event/detail')},
    {path: '/notice',               name: 'notice',          component: () => import('@/views/notice')},
    {path: '/inquiry',              name: 'inquiry',         component: () => import('@/views/inquiry/list'),       meta: { auth: true }},
    {path: '/inquiry/new',          name: 'inquiry/new',     component: () => import('@/views/inquiry/save'),       meta: { auth: true }},
    {path: '/inquiry/:idx',         name: 'inquiry/:idx',    component: () => import('@/views/inquiry/detail'),     meta: { auth: true }},
    {path: '/inquiry/:idx/modify',  name: 'inquiry/modify',  component: () => import('@/views/inquiry/save'),       meta: { auth: true }},
    {path: '/faq',                  name: 'faq',             component: () => import('@/views/faq')},

    {path: '/mypage/posts',         name: 'mypage/posts',    component: () => import('@/views/mypage/posts'),       meta: { auth: true }},
    {path: '/mypage/activity',      name: 'mypage/activity', component: () => import('@/views/mypage/activity'),    meta: { auth: true }},
    {path: '/mypage/modify',        name: 'mypage/modify',   component: () => import('@/views/mypage/modify'),      meta: { auth: true }},
    {path: '/mypage/password',      name: 'mypage/password', component: () => import('@/views/mypage/password'),    meta: { auth: true }},
    {path: '/mypage/withdrawal',    name: 'mypage/withdrawal',component: () => import('@/views/mypage/withdrawal'), meta: { auth: true }},
    
    {path: '/mypet/',               name: 'mypet',            component: () => import('@/views/mypet/index'),       meta: { auth: true }},
    {path: '/mypet/register',       name: 'mypet/register',   component: () => import('@/views/mypet/register'),    meta: { auth: true }},
    {path: '/mypet/modify/:idx',    name: 'mypet/modify/:idx',component: () => import('@/views/mypet/modify'),      meta: { auth: true }},
    {path: '/mypet/complete',       name: 'mypet/complete',   component: () => import('@/views/mypet/complete'),    meta: { auth: true }},

    {path: '*',                     name: '404',              component: () => import('@/views/404')},
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.name === from.name) return null
        return { x: 0, y: 0 };
    }
})

router.beforeEach((to, from, next) => {
    const isLogin = localStorage.getItem('auth')
    if (to.meta.auth && !isLogin) {
        console.log('인증이 필요합니다')
        next('/login')
        return false
    }
    if (to.meta.noAuth && isLogin && to.path !== '/newPassword') {
        console.log('이미 로그인된 상태입니다')
        next('/')
        return false
    }
    next()
});

export default router