import Component from './Component'

export default {
    install(Vue) {
        const component = Vue.extend(Component)
        let mw = null

        window.$toast = params => {
            this.setMessage(params, 'toast')
        }

        window.$toast.hide = () => {
            mw.hide()
        }

        this.setMessage = (opt) => {
            let msg = ''

            if (mw == null) {
                mw = new component({el: document.createElement('div')})
                document.body.appendChild(mw.$el)
            }

            if (typeof opt == 'object') {
                msg = opt.message
            } else {
                msg = opt
            }

            // mw.msg = msg

            mw.show(msg)
        }
    }
}