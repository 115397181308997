const session = {
    namespaced: true,
    state: {
        authToken: null,
        sleepToken: null,
        id: null,
        email: null,
        name: null,
        url: null,
        target: null,
        phone: null,
        joinSnsInfo: {
            target: null,
            token: null,
            email: null,
        },
    },
    mutations: {
        MUT_SET_SESSION(state, payload) {
            if (payload.authToken) state.authToken = payload.authToken
            if (payload.id) state.id = payload.id
            if (payload.email) state.email = payload.email
            if (payload.name) state.name = payload.name
            if (payload.url) state.url = payload.url
            if (payload.target) state.target = payload.target
            if (payload.phone) state.phone = payload.phone
        },
        MUT_SET_CLEAR_SESSION(state) {
            state.authToken = null
            state.id = null
            state.email = null
            state.name = null
            state.url = null
            state.target = null
            state.phone = null
        },
        MUT_SET_SLEEP_TOKEN(state, payload) {
            state.sleepToken = payload.sleepToken
        }
    },
    actions: {
        setSession({commit}, payload) {
            commit("MUT_SET_SESSION", payload);
        },
        clearSession({commit}) {
            commit("MUT_SET_CLEAR_SESSION");
        },
        setSleepToken({commit}, payload) {
            commit("MUT_SET_SLEEP_TOKEN", payload);
        }
    },
    getters: {
        isLogin: state => {
            return state.authToken && state.email && state.name ? true : false
        },
        getAuthToken: state => {
            return state.authToken
        },
        getSleepToken: state => {
            return state.sleepToken
        },
        getId: state => {
            return state.id
        },
        getEmail: state => {
            return state.email
        },
        getName: state => {
            return state.name
        },
        getUrl: state => {
            return state.url
        },
        getTarget: state => {
            return state.target
        },
        getPhone: state => {
            return state.phone
        },
    },
}

export default session