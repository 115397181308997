<template>
  <div class="popupBox-wrap" style="z-index:999">
    <div class="popupPosition"></div>
    <div class="popupBox w-600">
      <div class="popupBox-title center">{{ $msg('confirm') }}</div>
      <div class="popupBox-body ta-left">{{ confirmMessage }}</div>
      <div v-if="confirmType === 'confirm'" class="popupBox-bottom">
        <button @click.stop="onNoClick" class="w-90 mr-10 btn btn-sm btn-outline color-6">{{ $msg('cancel') }}</button>
        <button @click.stop="onYesClickWrapper" class="w-90 btn btn-sm fw-bold color-1">{{ $msg('confirm') }}</button>
      </div>
      <div v-else class="popupBox-bottom">
        <button @click.stop="onYesClickWrapper" v-focus class="w-90 btn btn-sm fw-bold color-1">{{ $msg('confirm') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['confirmType', 'onYesClick', 'onNoClick', 'confirmMessage', 'confirmTitle', 'confirmParam1', 'confirmTarget'],
  data() {
    return {
    }
  },
  methods: {
    onYesClickWrapper(event) {
      this.onYesClick(event);
    },
  }
}

</script>
