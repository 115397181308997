<template>
  <layout>
    <router-view />

    <confirm-popup v-if="getMsgPopup.message" :confirmType="getMsgPopup.type"
                   :onYesClick="getMsgPopup.okayCallback" :onNoClick="getMsgPopup.cancelCallback"
                   :confirmMessage="getMsgPopup.message"/>
  </layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Layout from '@/layouts'
import scriptMixin from "@/mixins/scriptMixin"
import ConfirmPopup from "@/components/common/ConfirmPopup"
import '@/assets/scss/_index.scss'

export default {
  mixins: [scriptMixin],
  data() {
    return {
    }
  },
  components: {Layout, ConfirmPopup},
  watch: {
    $route() {
      this.removeLocalStorage()
      document.body.style.overflowY = 'scroll'
    }
  },
  computed: {
    ...mapGetters({
      getMsgPopup: 'common/getMsgPopup',
    }),
  },
  created() {
    console.log(process.env.NODE_ENV === 'development' ? "[개발]" : "[운영]")

    this.setJwtInfo()
    this.setCommonCode()

    if (this.$isMobileCheck()) {
      if (process.env.NODE_ENV === 'production') {
        location.replace(`https://m.ggolab.co.kr${location.pathname}${location.search}`)
      }
      else {
        location.replace(`https://ggolabm.programrush.co.kr${location.pathname}${location.search}`)
      }
    }
  },
  methods: {
    ...mapActions({
      setCode: 'common/setCode',
      setSession: 'session/setSession',
      setMsgPopup: 'common/setMsgPopup',
    }),
    setJwtInfo() {
      const token = localStorage.getItem('auth')
      if (token) {
        this.setSession({
          authToken: token,
          id: localStorage.getItem('id'),
          email: localStorage.getItem('email'),
          name: localStorage.getItem('name'),
          url: localStorage.getItem('url'),
          target: localStorage.getItem('target'),
          phone: localStorage.getItem('phone'),
        })
      }
    },
    async setCommonCode() {
      const { result, data } = await this.$api.getCodeAll()
      if (result === 'success') this.setCode(data)
    },
    async getWordFilter() {
      const { result, data } = await this.$api.getWordFilter()
      if (result === 'success') this.setWordFilter({ wordFilter: data })
    },
    removeLocalStorage() {
      if (this.$route.path !== '/sleep') localStorage.removeItem('sleep')
      else if (this.$route.path !== '/login') localStorage.removeItem('loginBeforePage')
    }
  }
}
</script>