import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

// sns
if (process.env.NODE_ENV === 'production') {
  window.Kakao.init('bd39c8b1d94a037d570f683c1798e8c8')
  Vue.prototype.$kakaoId = '596499674e760780b8f4926647896570'
  Vue.prototype.$kakaoCallback = 'https://www.ggolab.co.kr/login/kakao'
  Vue.prototype.$naverId = 'S7_7kDS1kM3JXC2GXV5T'
  Vue.prototype.$naverCallback = 'https://www.ggolab.co.kr/login/naver'
  Vue.prototype.$facebookId = '4189953677784145'
  Vue.prototype.$facebookCallback = 'https://www.ggolab.co.kr/login/facebook'
  Vue.prototype.$joinCallback = 'https://www.ggolab.co.kr/join'
  window.fbAsyncInit = function() {
    FB.init({
      appId            : Vue.prototype.$facebookId,
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v12.0'
    })
  }
}
else {
  window.Kakao.init('6e14d46b90946e11239000f249fa80fc')
  Vue.prototype.$kakaoId = 'a4e4313068311711cd967568f2e51d93'
  Vue.prototype.$kakaoCallback = `${location.origin}/login/kakao`
  Vue.prototype.$naverId = 'wc6hvBfU2w1cv_9Q3uRx'
  Vue.prototype.$naverCallback = `${location.origin}/login/naver`
  Vue.prototype.$facebookId = '273051621491115'
  Vue.prototype.$facebookCallback = `${location.origin}/login/facebook`
  Vue.prototype.$joinCallback = `${location.origin}/join`
  window.fbAsyncInit = function() {
    FB.init({
      appId            : Vue.prototype.$facebookId,
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v12.0'
    })
  }
}

// 이미지 최대 크기
Vue.prototype.$imageMaxWidth = 2560
Vue.prototype.imageMaxHeight = 7680

// cookie
import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

// axios
import api from './api'
Vue.prototype.$api = new api()

// 메시지
import message from '@/plugins/message'
Vue.prototype.$msg = (str) => {
  return message[str]
}

// eventBus
Vue.prototype.$eventbus = new Vue()

// calendar
/*import VCalendar from 'v-calendar'
Vue.use(VCalendar)*/

// utils
import util from './utils/util'
Vue.use(util)
import dateUtil from './utils/dateUtil'
Vue.use(dateUtil)

// toast
import toast from '@/utils/toast'
Vue.use(toast)

// 전역 사용자 정의 디렉티브 v-click-outside 등록
import clickOutside from 'vue-click-outside'
Vue.directive("clickOutside", clickOutside)

// 전역 사용자 정의 디렉티브 v-focus 등록
Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

// 전역 컴포넌트 선언
Vue.component('InfiniteLoading', () => import('vue-infinite-loading'))
Vue.component('GlobalHeader', () => import('@/components/common/Header'))
Vue.component('GlobalFooter', () => import('@/components/common/Footer'))
Vue.component('MdTab', () => import('@/components/common/MdTab'))
Vue.component('MypageTab', () => import('@/components/common/MypageTab'))
Vue.component('CsCenterTab', () => import('@/components/common/CsCenterTab'))
Vue.component('InputField', () => import('@/components/common/InputField'))
Vue.component('SelectBox', () => import('@/components/common/SelectBox'))
Vue.component('ValidateForm', () => import('@/components/common/ValidateForm'))
Vue.component('PhoneVerify', () => import('@/components/common/PhoneVerify'))
Vue.component('Timer', () => import('@/components/common/Timer'))
Vue.component('Agree', () => import('@/components/common/Agree'))
Vue.component('Pagination', () => import('@/components/common/Pagination'))
Vue.component('Card', () => import('@/components/common/Card'))
Vue.component('Profile', () => import('@/components/common/Profile'))
Vue.component('QuickBox', () => import('@/components/common/QuickBox'))
Vue.component('Comment', () => import('@/components/common/Comment'))
Vue.component('Recommend', () => import('@/components/common/Recommend'))
Vue.component('IconLabel', () => import('@/components/common/IconLabel'))
Vue.component('ImageAlt', () => import('@/components/common/ImageAlt'))

Vue.config.productionTip = false



new Vue({
  router,
  store,
  message,
  render: h => h(App),
}).$mount('#app')
