import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import session from '@/store/modules/session.js'
import common from '@/store/modules/common.js'

const store = new Vuex.Store({
    modules: {
        session: session,
        common: common,
    }
})

export default store