export default {
  data() {
    return {
    }
  },
  mounted() {
    this.getScript('head').then()
    this.getScript('body').then()
  },
  methods: {
    async getScript(target) {
      const { result, data } = await this.$api.getScript({ target: target})
      if (result === 'success') {
        if (data.input.length > 0) {
          for (let src of data.input) {
            let script = document.createElement('script')
            script.setAttribute('src', src)
            document[target].appendChild(script)
          }
        }
        setTimeout(() => {
          if (data.script.length > 0) {
            let script = document.createElement('script')
            script.innerHTML = data.script
            document[target].appendChild(script)
          }
        }, 500)
      }
    },
  },
}
